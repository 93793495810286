<script>
export default {
  name: 'Textarea',
  inheritAttrs: false,
  props: {
    size: {
      type: String,
      required: false,
      default: 'small',
      validator: function(value) {
        return ['small'].includes(value);
      },
    },
    color: {
      type: String,
      required: false,
      default: 'light',
      validator: function(value) {
        return ['light'].includes(value);
      },
    },
    invalid: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Textarea';
</style>

<template>
  <div :class="['textarea', size, color, {'invalid': invalid}]">
    <textarea v-bind="$attrs"
              v-html="$slots.default ? $slots.default[0].text : null"/>
  </div>
</template>
