const getAdvancedSearchProperties = () => {
  return fetch(`${process.env.VUE_APP_API_BASE_URL}/v1/searchProperties`).then(response => response.json()).then(response => {
    function buildTree(rootId) {
      const properties = [];

      response.forEach(x => {
        if (x.parent === rootId) {
          properties.push({
            ...x,
            children: buildTree(x.id),
          });
        }
      });

      return properties;
    }

    return {
      configuration: buildTree(1),
      orientation: buildTree(2),
      movement: buildTree(3),
      location: buildTree(4),
    };
  });
};

const advancedSearch = (query) => {
  let params = new URLSearchParams(query);

  return fetch(`${process.env.VUE_APP_API_BASE_URL}/v1/searchAdvanced?${params.toString()}`).then(response => response.json());
};

const textSearch = (query) => {
  if (!query || query === '') {
    return Promise.resolve([]);
  }

  return fetch(`${process.env.VUE_APP_API_BASE_URL}/v1/searchText?q=${query}`).then(response => response.json());
};

export {
  getAdvancedSearchProperties,
  advancedSearch,
  textSearch,
};
