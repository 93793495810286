<script>
export default {
  name: 'VideoPlayer',
  props: {
    source: {
      type: String,
      required: false,
    },
    replayControl: {
      type: Boolean,
      required: false,
      default: false,
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isPlaying: this.autoplay,
    };
  },
  mounted() {
    this.$refs.video.addEventListener('play', () => {
      this.isPlaying = true;
    });

    this.$refs.video.addEventListener('pause', () => {
      this.isPlaying = false;
    });
  },
  methods: {
    toggle: function() {
      const video = this.$refs.video;

      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'VideoPlayer';
</style>

<template>
  <div :class="['video-player']">
    <button v-if="!replayControl"
            :class="['button', {'pause': isPlaying}, {'play': !isPlaying}]"
            @click="toggle()"/>

    <button v-if="replayControl && !isPlaying"
            class="button-replay"
            @click="toggle()"/>

    <video ref="video"
           :src="source"
           :autoplay="autoplay"
           playsinline
           muted/>
  </div>
</template>
