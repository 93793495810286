<script>
import Button from '@/components/ui/Button/Button';
import Container from '@/components/ui/Container/Container';
import FormControl from '@/components/ui/FormControl/FormControl';
import Grid from '@/components/ui/Grid/Grid';
import GridColumn from '@/components/ui/Grid/GridColumn';
import Input from '@/components/ui/Input/Input';
import Textarea from '@/components/ui/Textarea/Textarea';
import VideoPlayer from '@/components/ui/VideoPlayer/VideoPlayer';

export default {
  name: 'Contact',
  components: {
    Button,
    Container,
    FormControl,
    Grid,
    GridColumn,
    Input,
    Textarea,
    VideoPlayer,
  },
};
</script>

<style lang="scss" scoped>
@import 'Contact';
</style>

<template>
  <div class="scene">
    <div class="head">
      <Container>
        <Grid align="middle">
          <GridColumn size="1of2">
            <h1>
              Nous contacter
            </h1>

            <img alt="ALSF"
                 src="@/assets/partners/alsf.png"/>

            <p>
              Académie de la Langue des Signes Française<br/>
              3 rue Léon Giraud<br/>
              75019 PARIS
            </p>
          </GridColumn>

          <GridColumn size="1of2">
            <VideoPlayer source=""/>
          </GridColumn>
        </Grid>

      </Container>
    </div>

    <div class="form">
      <Container>
        <Grid>
          <GridColumn size="1of2">
            <FormControl>
              <label>Votre nom</label>
              <Input type="text"
                     placeholder="Écrire ici..."/>
            </FormControl>
          </GridColumn>

          <GridColumn size="1of2">
            <FormControl>
              <label>Votre prénom</label>
              <Input type="text"
                     placeholder="Écrire ici..."/>
            </FormControl>
          </GridColumn>

          <GridColumn size="1of2">
            <FormControl>
              <label>Votre adresse e-mail</label>
              <Input type="email"
                     placeholder="Écrire ici..."/>
            </FormControl>
          </GridColumn>

          <GridColumn size="1of2">
            <FormControl>
              <label>Objet</label>
              <Input type="text"
                     placeholder="Écrire ici..."/>
            </FormControl>
          </GridColumn>

          <GridColumn size="1of1">
            <FormControl>
              <label>Votre message</label>
              <Textarea placeholder="Écrire ici..."/>
            </FormControl>
          </GridColumn>

          <GridColumn size="1of1"
                      align="center">
            <Button size="medium"
                    type="submit">
              Envoyer
            </Button>
          </GridColumn>
        </Grid>
      </Container>
    </div>
  </div>
</template>
