<script>
export default {
  name: 'Header',
};
</script>

<style lang="scss" scoped>
@import 'Header';
</style>

<template>
  <div class="header">
    <div class="brand">
      <router-link to="/">
        <img alt="Pilingue"
             src="@/assets/brand.png"/>
      </router-link>

      <hr/>

      <p>
        Le 1er dictionnaire<br/>
        LSF
        <img alt=""
             src="@/assets/icons/exchange.png"/>
        Français
      </p>
    </div>

    <ul>
      <li>
        <router-link to="/">
          Rechercher
        </router-link>
      </li>
      <li>
        <router-link to="/comment-ca-marche">
          Comment ça marche ?
        </router-link>
      </li>
      <li>
        <router-link to="/presentation">
          Présentation
        </router-link>
      </li>
    </ul>
  </div>
</template>
