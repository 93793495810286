<script>
import Grid from '@/components/ui/Grid/Grid';
import GridColumn from '@/components/ui/Grid/GridColumn';
import VideoPlayer from '@/components/ui/VideoPlayer/VideoPlayer';

export default {
  name: 'ResultsPanel',
  components: {
    Grid,
    GridColumn,
    VideoPlayer,
  },
  props: {
    results: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      currentResult: null,
    };
  },
  methods: {
    reset: function() {
      this.currentResult = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'ResultsPanel';
</style>

<template>
  <div class="results-panel">
    <div class="empty"
         v-if="!results || !results.length">
      <img class="icon"
           alt="Résultats"
           src="@/assets/icons/search-alt.png"/>

      <p>
        {{ results === null ? 'Pas encore de résultats...' : 'Aucun résultat trouvé...' }}
      </p>
    </div>

    <div class="item-list"
         v-if="results && results.length && currentResult === null">
      <p class="head">
        <b>{{ results.length + ' ' + (results.length !== 1 ? 'résultats' : 'résultat') }}</b>
        {{ results.length !== 1 ? 'correspondent' : 'correspond' }} à votre recherche
      </p>

      <div class="items">
        <vue-scroll>
          <div class="scroll-inner">
            <Grid>
              <GridColumn size="1of3"
                          v-for="(result, i) in results"
                          :key="i">
                <div class="item"
                     @click="currentResult = i">
                  <img class="thumbnail"
                       :alt="result.name"
                       :src="result.thumbnail"/>

                  <span class="title">{{ result.name }}</span>
                </div>
              </GridColumn>
            </Grid>
          </div>
        </vue-scroll>
      </div>
    </div>

    <div class="item-single"
         v-if="results && results.length && currentResult !== null">
      <button class="go-back"
              @click="reset()">
        <img alt=""
             src="@/assets/icons/arrow-left-alt.png"/>
      </button>

      <VideoPlayer :source="results[currentResult].video"
                   :replayControl="true"
                   :autoplay="true"/>

      <span class="title"
            v-html="results[currentResult].name"/>

      <ul class="tags"
          v-if="results[currentResult].tags">
        <li v-for="(tag, i) in results[currentResult].tags"
            :key="i">
          <img :alt="tag.name"
               :src="tag.thumbnail"/>
          {{ tag.name }}
        </li>
      </ul>
    </div>
  </div>
</template>
