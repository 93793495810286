<script>
import Button from '@/components/ui/Button/Button';
import Container from '@/components/ui/Container/Container';
import Grid from '@/components/ui/Grid/Grid';
import GridColumn from '@/components/ui/Grid/GridColumn';

import {advancedSearch, textSearch} from '@/services/search';

import FormText from '@/components/scenes/Search/components/FormText/FormText';
import FormAdvanced from '@/components/scenes/Search/components/FormAdvanced/FormAdvanced';
import ResultsPanel from '@/components/scenes/Search/components/ResultsPanel/ResultsPanel';

export default {
  name: 'Search',
  components: {
    FormText,
    FormAdvanced,
    ResultsPanel,
    Button,
    Container,
    Grid,
    GridColumn,
  },
  data() {
    return {
      searchMode: 'advanced',
      showSearchModeSelector: false,
      textSearchValue: null,
      advancedSearchQuery: null,
      isFetchingResults: false,
      results: null,
    };
  },
  methods: {
    toggleSearchModeSelector: function() {
      this.showSearchModeSelector = !this.showSearchModeSelector;
    },
    invertSearchMode: function() {
      this.searchMode = this.searchMode === 'text' ? 'advanced' : 'text';
      this.textSearchValue = null;
      this.advancedSearchQuery = null;
      this.results = null;
    },
    search: function() {
      this.isFetchingResults = true;

      if (this.searchMode === 'text') {
        textSearch(this.textSearchValue).then(results => {
          this.results = results;
          this.isFetchingResults = false;
        });

        return;
      }

      advancedSearch(this.advancedSearchQuery).then(results => {
        this.results = results;
        this.isFetchingResults = false;
      });
    },
    updateAdvancedSearch: function(value) {
      this.advancedSearchQuery = value;

      const isNullish = Object.values(this.advancedSearchQuery).every(value => {
        return value === null;
      });

      if (!isNullish) {
        this.search();
      } else {
        this.results = null;
      }
    },
    reset: function() {
      this.$refs.formAdvanced.reset();
      this.results = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Search';
</style>

<template>
  <div class="scene">
    <Container>
      <div class="inner">
        <Grid>
          <GridColumn size="1of2">
            <div class="toolbar">
              <div class="left">
                <img alt=""
                     src="@/assets/icons/loupe.png"/>
                Mode de recherche
              </div>

              <div :class="['right', {'active': showSearchModeSelector}]"
                   @click="toggleSearchModeSelector()">
                <img alt=""
                     :src="require(`@/assets/icons/${searchMode === 'advanced' ? 'signs' : 'write'}.png`)"/>
                {{ searchMode === 'advanced' ? 'LSF' : 'Français' }}

                <img class="arrow"
                     alt=""
                     src="@/assets/icons/arrow-down.png"/>

                <ul>
                  <li @click="invertSearchMode()">
                    <img alt=""
                         :src="require(`@/assets/icons/${searchMode !== 'advanced' ? 'signs' : 'write'}.png`)"/>
                    {{ searchMode !== 'advanced' ? 'LSF' : 'Français' }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="forms">
              <FormText v-if="searchMode === 'text'"
                        @change="value => this.textSearchValue = value"
                        @submit="search()"/>

              <FormAdvanced ref="formAdvanced"
                            v-if="searchMode === 'advanced'"
                            @change="value => updateAdvancedSearch(value)"/>
            </div>

            <button v-if="searchMode === 'advanced'"
                    class="reset-filters"
                    @click="reset()">
              <img alt=""
                   src="@/assets/icons/close-alt.png"/>
              Tout effacer
            </button>

            <Button size="medium"
                    color="purple"
                    :disabled="isFetchingResults"
                    @click="search()">
              <img alt=""
                   src="@/assets/icons/search.png"/>
              Rechercher
            </Button>
          </GridColumn>

          <GridColumn class="fluid"
                      size="1of2">
            <div class="results">
              <div class="spinner"
                   v-if="isFetchingResults"/>

              <ResultsPanel :results="results"
                            v-if="!isFetchingResults"/>
            </div>
          </GridColumn>
        </Grid>
      </div>
    </Container>
  </div>
</template>
