<script>
import Container from '@/components/ui/Container/Container';
import Grid from '@/components/ui/Grid/Grid';
import GridColumn from '@/components/ui/Grid/GridColumn';
import VideoPlayer from '@/components/ui/VideoPlayer/VideoPlayer';

export default {
  name: 'About',
  components: {
    Container,
    Grid,
    GridColumn,
    VideoPlayer,
  },
};
</script>

<style lang="scss" scoped>
@import 'About';
</style>

<template>
  <div class="scene">
    <div class="section">
      <Container>
        <Grid align="middle">
          <GridColumn align="center">
            <h1>
              Pourquoi Pilingue ?
            </h1>
          </GridColumn>

          <GridColumn align="center"
                      size="1of2">
            <img alt=""
                 src="@/assets/why/why-illustration.png"/>
          </GridColumn>

          <GridColumn size="1of2">
            <VideoPlayer source=""/>

            <p class="primary">
              Nulla imperdiet sapien et tincidunt consectetur. Nam imperdiet ex sit amet nisi dignissim, et pharetra
              risus tempor.
            </p>

            <p>
              Nullam eget sapien non orci pharetra dapibus non id felis. Donec viverra nec sem vel congue. Donec congue
              est eros, sit amet mollis magna laoreet id. Mauris aliquet, augue fermentum sollicitudin rhoncus, odio
              tellus suscipit orci, in placerat arcu felis quis justo. Praesent egestas, tellus at posuere ullamcorper,
              nisl elit cursus nibh, non iaculis felis
            </p>
          </GridColumn>
        </Grid>
      </Container>
    </div>

    <div class="alternate section">
      <Container>
        <Grid>
          <GridColumn align="center">
            <h1>
              Pilingue c’est pour qui ?
            </h1>
          </GridColumn>

          <GridColumn size="1of2">
            <VideoPlayer source=""/>

            <p class="primary">
              Nulla imperdiet sapien et tincidunt consectetur. <br/>
              Nam imperdiet ex sit amet nisi dignissim, <br/>
              et pharetra risus tempor.
            </p>
          </GridColumn>

          <GridColumn size="1of2">
            <p>
              Nullam eget sapien non orci pharetra dapibus non id felis. Donec viverra nec sem vel congue. Donec congue
              est eros, sit amet mollis magna laoreet id. Mauris aliquet, augue fermentum sollicitudin rhoncus, odio
              tellus suscipit orci, in placerat arcu felis quis justo. Praesent egestas, tellus at posuere ullamcorper,
              nisl elit cursus nibh, non iaculis felis
            </p>

            <p>
              Nullam eget sapien non orci pharetra dapibus non id felis. Donec viverra nec sem vel congue. Donec congue
              est eros, sit amet mollis magna laoreet id. Mauris aliquet, augue fermentum sollicitudin rhoncus, odio
              tellus suscipit orci, in placerat arcu felis quis justo. Praesent egestas, tellus at posuere ullamcorper,
              nisl elit cursus nibh, non iaculis felis
            </p>
          </GridColumn>

          <GridColumn>
            <img alt=""
                 src="@/assets/why/why-deco.png"/>
          </GridColumn>
        </Grid>
      </Container>
    </div>

    <div class="last section">
      <Container>
        <Grid>
          <GridColumn align="center">
            <h1>
              Évolutions à venir...
            </h1>
          </GridColumn>

          <GridColumn size="1of2">
            <VideoPlayer source=""/>

            <p class="primary">
              Nulla imperdiet sapien et tincidunt consectetur. Nam imperdiet ex sit amet nisi dignissim, et pharetra
              risus tempor.
            </p>

            <p>
              Nullam eget sapien non orci pharetra dapibus non id felis. Donec viverra nec sem vel congue. Donec congue
              est eros, sit amet mollis magna laoreet id. Mauris aliquet, augue fermentum sollicitudin rhoncus, odio
              tellus suscipit orci, in placerat arcu felis quis justo. Praesent egestas, tellus at posuere ullamcorper,
              nisl elit cursus nibh, non iaculis felis
            </p>
          </GridColumn>

          <GridColumn align="right"
                      size="1of2">
            <img alt=""
                 src="@/assets/why/why-phone.png"/>
          </GridColumn>
        </Grid>
      </Container>
    </div>
  </div>
</template>
