<script>
import Container from '@/components/ui/Container/Container';
import Grid from '@/components/ui/Grid/Grid';
import GridColumn from '@/components/ui/Grid/GridColumn';

export default {
  name: 'Footer',
  components: {
    Container,
    Grid,
    GridColumn,
  },
};
</script>

<style lang="scss" scoped>
@import 'Footer';
</style>

<template>
  <div class="footer">
    <div class="support">
      <Container>
        <span class="title">Avec le soutien de</span>

        <div class="partners">
          <Grid>
            <GridColumn size="1of3">
              <div class="partner">
                <img alt=""
                     src="@/assets/partners/alsf.png"/>
              </div>
            </GridColumn>

            <GridColumn size="1of3">
              <div class="partner">
                <img alt=""
                     src="@/assets/partners/total-energies.png"/>
              </div>
            </GridColumn>

            <GridColumn size="1of3">
              <div class="partner">
                <img alt=""
                     src="@/assets/partners/keia.png"/>
              </div>
            </GridColumn>

            <GridColumn size="1of3">
              <div class="partner">
                <img alt=""
                     src="@/assets/partners/fpa.png"/>
              </div>
            </GridColumn>


            <GridColumn size="1of3">
              <div class="partner">
                <img alt=""
                     src="@/assets/partners/fnsf.png"/>
              </div>
            </GridColumn>

            <GridColumn size="1of3">
              <router-link class="partner support-us"
                           to="/contact">
                <img alt=""
                     src="@/assets/icons/heart.png"/>

                <p>
                  Particulier, Institution...
                  <b>envie de nous <br/> soutenir ?</b>
                </p>
              </router-link>
            </GridColumn>
          </Grid>
        </div>
      </Container>
    </div>

    <div class="terms">
      <p>
        ® Pilingue 2022 Tous Droits Réservés - Académie de la Langue des signes Françaises
      </p>

      <ul>
        <li>
          <router-link to="/contact">Contact</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
