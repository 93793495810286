<script>
import Header from '@/components/layout/Header/Header';
import Footer from '@/components/layout/Footer/Footer';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss">
@import './styles/app/reset';
@import './styles/app/base';
</style>

<template>
  <div id="app">
    <Header/>
    <router-view/>
    <Footer/>
  </div>
</template>
