<script>
export default {
  name: 'Container',
};
</script>

<style lang="scss" scoped>
@import 'Container';
</style>

<template>
  <div class="container">
    <slot/>
  </div>
</template>
