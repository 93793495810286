<script>
export default {
  name: 'FormControl',
};
</script>

<style lang="scss" scoped>
@import 'FormControl';
</style>

<template>
  <div :class="['form-control']">
    <slot/>
  </div>
</template>
