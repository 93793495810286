<script>
export default {
  name: 'Button',
  props: {
    size: {
      type: String,
      required: false,
      default: 'small',
      validator: function(value) {
        return ['small', 'medium'].includes(value);
      },
    },
    color: {
      type: String,
      required: false,
      default: 'white',
      validator: function(value) {
        return ['white', 'purple'].includes(value);
      },
    },
    href: {
      type: String,
      required: false,
    },
  },
  computed: {
    type() {
      if (this.href) {
        return 'a';
      }

      return 'button';
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Button';
</style>

<template>
  <component :is="type"
             :class="['button', size, color]"
             :href="href"
             @click="$emit('click', $event)">
    <slot/>
  </component>
</template>
