<script>
import Grid from '@/components/ui/Grid/Grid';
import GridColumn from '@/components/ui/Grid/GridColumn';

import {getAdvancedSearchProperties} from '@/services/search';

export default {
  name: 'FormAdvanced',
  components: {
    Grid,
    GridColumn,
  },
  data() {
    return {
      panel: {
        active: false,
        deep: false,
        title: null,
        parameter: null,
        property: null,
        choices: [],
        selection: null,
      },
      query: {
        twoHands: false,
        firstHandConfiguration: null,
        firstHandOrientation: null,
        firstHandMovement: null,
        secondHandConfiguration: null,
        secondHandOrientation: null,
        secondHandMovement: null,
        location: null,
      },
      properties: {
        configuration: [],
        orientation: [],
        movement: [],
        location: [],
      },
    };
  },
  watch: {
    query: {
      deep: true,
      immediate: true,
      handler(query) {
        let output = {};

        if (query.firstHandConfiguration !== null) {
          output['firstHandConfiguration'] = this.query.firstHandConfiguration.id;
        }

        if (query.firstHandOrientation !== null) {
          output['firstHandOrientation'] = this.query.firstHandOrientation.id;
        }

        if (query.firstHandMovement !== null) {
          output['firstHandMovement'] = this.query.firstHandMovement.id;
        }

        if (query.secondHandConfiguration !== null) {
          output['secondHandConfiguration'] = this.query.secondHandConfiguration.id;
        }

        if (query.secondHandOrientation !== null) {
          output['secondHandOrientation'] = this.query.secondHandOrientation.id;
        }

        if (query.secondHandMovement !== null) {
          output['secondHandMovement'] = this.query.secondHandMovement.id;
        }

        if (query.location !== null) {
          output['location'] = this.query.location.id;
        }

        this.$emit('change', output);
      },
    },
  },
  mounted() {
    this.refreshProperties();
  },
  methods: {
    refreshProperties: function() {
      getAdvancedSearchProperties().then(properties => {
        this.properties.configuration = properties.configuration;
        this.properties.orientation = properties.orientation;
        this.properties.movement = properties.movement;
        this.properties.location = properties.location;
      });
    },
    toggleTwoHands: function() {
      this.query.twoHands = !this.query.twoHands;

      if (!this.query.twoHands) {
        this.query.secondHandConfiguration = null;
        this.query.secondHandOrientation = null;
        this.query.secondHandMovement = null;
      }
    },
    configureParameter: function($event, parameter, property, title) {
      if ($event.target.classList.contains('recycle')) {
        return;
      }

      if (this.query[parameter] &&
          this.properties[property].find(x => x.id === this.query[parameter].parent)) {
        this.panel.deep = true;
        this.panel.choices = this.properties[property].find(
            x => x.id === this.query[parameter].parent).children;
      } else {
        this.panel.deep = false;
        this.panel.choices = this.properties[property];
      }

      this.panel.active = true;
      this.panel.title = title;
      this.panel.parameter = parameter;
      this.panel.property = property;
      this.panel.selection = this.query[this.panel.parameter] ?? null;
    },
    selectParameterProperty: function(item) {
      this.panel.selection = item;
      this.applyParameterConfiguration();
    },
    cancelParameterConfiguration: function() {
      if (this.panel.deep) {
        this.panel.deep = false;
        this.panel.choices = this.properties[this.panel.property];
        return;
      }

      this.panel.active = false;
      this.panel.deep = false;
      this.panel.title = null;
      this.panel.parameter = null;
      this.panel.property = null;
      this.panel.choices = null;
      this.panel.selection = null;
    },
    applyParameterConfiguration: function() {
      if (this.panel.selection && this.panel.selection.children.length) {
        this.panel.deep = true;
        this.panel.choices = this.panel.selection.children;
        return;
      }

      this.query[this.panel.parameter] = this.panel.selection;
      this.panel.active = false;
      this.panel.deep = false;
      this.panel.title = null;
      this.panel.parameter = null;
      this.panel.property = null;
      this.panel.choices = null;
      this.panel.selection = null;
    },
    resetParameter: function(parameter) {
      this.query[parameter] = null;
    },
    reset: function() {
      this.panel.active = false;
      this.panel.deep = false;
      this.panel.title = null;
      this.panel.parameter = null;
      this.panel.property = null;
      this.panel.choices = [];
      this.panel.selection = null;

      this.query.firstHandConfiguration = null;
      this.query.firstHandOrientation = null;
      this.query.firstHandMovement = null;
      this.query.secondHandConfiguration = null;
      this.query.secondHandOrientation = null;
      this.query.secondHandMovement = null;
      this.query.location = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'FormAdvanced';
</style>

<template>
  <div class="form-advanced">
    <Grid :spaced="false"
          v-if="!panel.active">
      <!-- hands -->
      <GridColumn size="1of2">
        <div :class="['primary', 'selectable', 'panel', 'active']">
          <img alt=""
               class="hand"
               src="@/assets/icons/hand-left.png"/>
        </div>
      </GridColumn>

      <GridColumn size="1of2">
        <div
            :class="['primary', 'selectable', 'closable', 'panel', {'active': query.twoHands === true}]"
            @click="toggleTwoHands()">
          <img v-if="!query.twoHands"
               alt=""
               class="hand"
               src="@/assets/icons/hand-right-off.png"/>

          <img v-if="query.twoHands"
               alt=""
               class="hand"
               src="@/assets/icons/hand-right.png"/>
        </div>
      </GridColumn>

      <!-- configuration -->
      <GridColumn :size="query.twoHands ? '1of2' : '1of1'">
        <div
            :class="['secondary', 'selectable', 'panel', {'active': query.firstHandConfiguration !== null}]"
            @click="configureParameter($event, 'firstHandConfiguration', 'configuration', 'Configuration')">
          Configuration

          <img v-if="!query.firstHandConfiguration"
               alt=""
               src="@/assets/icons/filter-configuration.png"/>

          <img v-if="query.firstHandConfiguration"
               alt=""
               :src="query.firstHandConfiguration.thumbnail"/>

          <div v-if="query.firstHandConfiguration !== null"
               class="recycle"
               @click="resetParameter('firstHandConfiguration')"/>
        </div>
      </GridColumn>

      <GridColumn size="1of2"
                  v-if="query.twoHands">
        <div
            :class="['secondary', 'selectable', 'panel', {'active': query.secondHandConfiguration !== null}]"
            @click="configureParameter($event, 'secondHandConfiguration', 'configuration', 'Configuration')">
          Configuration

          <img v-if="!query.secondHandConfiguration"
               alt=""
               src="@/assets/icons/filter-configuration.png"/>

          <img v-if="query.secondHandConfiguration"
               alt=""
               :src="query.secondHandConfiguration.thumbnail"/>

          <div v-if="query.secondHandConfiguration !== null"
               class="recycle"
               @click="resetParameter('secondHandConfiguration')"/>
        </div>
      </GridColumn>

      <!-- orientation -->
      <GridColumn :size="query.twoHands ? '1of2' : '1of1'">
        <div
            :class="['secondary', 'selectable', 'panel', {'active': query.firstHandOrientation !== null}]"
            @click="configureParameter($event, 'firstHandOrientation', 'orientation', 'Orientation')">
          Orientation

          <img v-if="!query.firstHandOrientation"
               alt=""
               src="@/assets/icons/filter-orientation.png"/>

          <img v-if="query.firstHandOrientation"
               alt=""
               :src="query.firstHandOrientation.thumbnail"/>

          <div v-if="query.firstHandOrientation !== null"
               class="recycle"
               @click="resetParameter('firstHandOrientation')"/>
        </div>
      </GridColumn>

      <GridColumn size="1of2"
                  v-if="query.twoHands">
        <div
            :class="['secondary', 'selectable', 'panel', {'active': query.secondHandOrientation !== null}]"
            @click="configureParameter($event, 'secondHandOrientation', 'orientation', 'Orientation')">
          Orientation

          <img v-if="!query.secondHandOrientation"
               alt=""
               src="@/assets/icons/filter-orientation.png"/>

          <img v-if="query.secondHandOrientation"
               alt=""
               :src="query.secondHandOrientation.thumbnail"/>

          <div v-if="query.secondHandOrientation !== null"
               class="recycle"
               @click="resetParameter('secondHandOrientation')"/>
        </div>
      </GridColumn>

      <!-- movement -->
      <GridColumn :size="query.twoHands ? '1of2' : '1of1'">
        <div
            :class="['secondary', 'selectable', 'panel', {'active': query.firstHandMovement !== null}]"
            @click="configureParameter($event, 'firstHandMovement', 'movement', 'Mouvement')">
          Mouvement

          <img v-if="!query.firstHandMovement"
               alt=""
               src="@/assets/icons/filter-movement.png"/>

          <img v-if="query.firstHandMovement"
               alt=""
               :src="query.firstHandMovement.thumbnail"/>

          <div v-if="query.firstHandMovement !== null"
               class="recycle"
               @click="resetParameter('firstHandMovement')"/>
        </div>
      </GridColumn>

      <GridColumn size="1of2"
                  v-if="query.twoHands">
        <div
            :class="['secondary', 'selectable', 'panel', {'active': query.secondHandMovement !== null}]"
            @click="configureParameter($event, 'secondHandMovement', 'movement', 'Mouvement')">
          Mouvement

          <img v-if="!query.secondHandMovement"
               alt=""
               src="@/assets/icons/filter-movement.png"/>

          <img v-if="query.secondHandMovement"
               alt=""
               :src="query.secondHandMovement.thumbnail"/>

          <div v-if="query.secondHandMovement !== null"
               class="recycle"
               @click="resetParameter('secondHandMovement')"/>
        </div>
      </GridColumn>

      <!-- location -->
      <GridColumn>
        <div :class="['secondary', 'selectable', 'panel', {'active': query.location !== null}]"
             @click="configureParameter($event, 'location', 'location', 'Emplacement')">
          Emplacement

          <img v-if="!query.location"
               alt=""
               src="@/assets/icons/filter-location.png"/>

          <img v-if="query.location"
               alt=""
               :src="query.location.thumbnail"/>

          <div v-if="query.location !== null"
               class="recycle"
               @click="resetParameter('location')"/>
        </div>
      </GridColumn>
    </Grid>

    <Grid :spaced="false"
          v-if="panel.active">
      <GridColumn>
        <div class="panel">
          <div class="head">
            <img class="icon"
                 alt="Retour"
                 src="@/assets/icons/arrow-left.png"
                 @click="cancelParameterConfiguration()"/>
            {{ panel.title }}
          </div>

          <div class="body">
            <vue-scroll>
              <div class="scroll-inner">
                <Grid>
                  <GridColumn size="1of2"
                              v-for="item in panel.choices"
                              :key="item.id">
                    <div
                        :class="['selectable', 'panel', {'active': item === panel.selection || (panel.selection && item.id === panel.selection.parent)}]"
                        @click="selectParameterProperty(item)">
                      <img alt=""
                           :src="item.thumbnail"/>
                      <span v-if="panel.title === 'Configuration' && !panel.deep">{{
                          item.name
                        }}</span>
                    </div>
                  </GridColumn>
                </Grid>
              </div>
            </vue-scroll>
          </div>
        </div>
      </GridColumn>
    </Grid>
  </div>
</template>
