<script>
import Container from '@/components/ui/Container/Container';
import Grid from '@/components/ui/Grid/Grid';
import GridColumn from '@/components/ui/Grid/GridColumn';
import VideoPlayer from '@/components/ui/VideoPlayer/VideoPlayer';

export default {
  name: 'HowItWorks',
  components: {
    Container,
    Grid,
    GridColumn,
    VideoPlayer,
  },
};
</script>

<style lang="scss" scoped>
@import 'HowItWorks';
</style>

<template>
  <div class="scene">
    <Container>
      <Grid>
        <GridColumn align="center">
          <h1>
            Comment ça marche ?
          </h1>
        </GridColumn>

        <GridColumn align="center">
          <div class="section">
            <span class="section-number">01</span>

            <VideoPlayer source=""/>

            <h2>
              Choisissez votre mode de recherche
            </h2>
          </div>
        </GridColumn>

        <GridColumn align="center">
          <div class="section">
            <span class="section-number">02</span>

            <VideoPlayer source=""/>

            <h2>
              Choisissez votre mode de recherche
            </h2>
          </div>
        </GridColumn>

        <GridColumn align="center">
          <div class="section">
            <span class="section-number">03</span>

            <VideoPlayer source=""/>

            <h2>
              Choisissez votre mode de recherche
            </h2>
          </div>
        </GridColumn>

        <GridColumn align="center">
          <div class="section">
            <span class="section-number">04</span>

            <VideoPlayer source=""/>

            <h2>
              Choisissez votre mode de recherche
            </h2>
          </div>
        </GridColumn>

        <GridColumn align="center">
          <div class="last section">
            <span class="section-number">05</span>

            <VideoPlayer source=""/>

            <h2>
              Choisissez votre mode de recherche
            </h2>
          </div>
        </GridColumn>
      </Grid>
    </Container>
  </div>
</template>
