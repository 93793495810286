<script>
export default {
  name: 'FormText',
  methods: {
    textareaInput: function(e) {
      this.$emit('change', e.target.value);
    },
    textareaKeyPress: function(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        this.$emit('submit', e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'FormText';
</style>

<template>
  <div class="form-text">
    <textarea placeholder="Écrire ici..."
              maxlength="22"
              @input="textareaInput"
              @keypress="textareaKeyPress"/>
  </div>
</template>
