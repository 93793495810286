<script>
export default {
  name: 'Input',
  inheritAttrs: false,
  props: {
    size: {
      type: String,
      required: false,
      default: 'small',
      validator: function(value) {
        return ['small'].includes(value);
      },
    },
    color: {
      type: String,
      required: false,
      default: 'light',
      validator: function(value) {
        return ['light'].includes(value);
      },
    },
    invalid: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Input';
</style>

<template>
  <div :class="['input', size, color, {'inline': ['checkbox', 'radio'].includes($attrs.type)}, {'invalid': invalid}]">
    <input v-bind="$attrs"
           @input="$emit('input', $event)"/>
  </div>
</template>
