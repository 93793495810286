import Vue from 'vue';
import VueRouter from 'vue-router';
import vuescroll from 'vuescroll';

import App from '@/App';
import About from '@/components/scenes/About/About';
import Contact from '@/components/scenes/Contact/Contact';
import HowItWorks from '@/components/scenes/HowItWorks/HowItWorks';
import Search from '@/components/scenes/Search/Search';

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(vuescroll, {
  ops: {
    scrollPanel: {
      scrollingX: false,
      scrollingY: true,
    },
    rail: {
      gutterOfSide: '0',
      specifyBorderRadius: '0',
    },
    bar: {
      onlyShowBarOnScroll: false,
      background: '#74b9c2',
      opacity: .35,
    },
  },
});

const router = new VueRouter({
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  },
  routes: [
    {
      path: '/',
      component: Search,
    },
    {
      path: '/presentation',
      component: About,
    },
    {
      path: '/comment-ca-marche',
      component: HowItWorks,
    },
    {
      path: '/contact',
      component: Contact,
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});

const app = new Vue({
  router,
  render: h => h(App),
});

app.$mount('#app');
