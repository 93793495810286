<script>
export default {
  name: 'Grid',
  props: {
    align: {
      type: String,
      required: false,
      default: 'top',
      validator: function(value) {
        return ['top', 'middle', 'bottom'].includes(value);
      },
    },
    spaced: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Grid';
</style>

<template>
  <div :class="['grid', align, {'spaced': spaced}]">
    <slot/>
  </div>
</template>
