<script>
export default {
  name: 'GridColumn',
  props: {
    align: {
      type: String,
      required: false,
      default: 'left',
      validator: function(value) {
        return ['left', 'center', 'right'].includes(value);
      },
    },
    size: {
      type: String,
      required: false,
      default: '1of1',
      validator: function(value) {
        return [
          '1of1',
          '1of10',
          '1of5',
          '1of4',
          '3of10',
          '1of3',
          '4of10',
          '1of2',
          '6of10',
          '3of3',
          '7of10',
          '3of4',
        ].includes(value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'GridColumn';
</style>

<template>
  <div :class="['column', align, 's'.concat(size)]">
    <slot/>
  </div>
</template>
